// -----------------------------------------------------------------------------
// This file contains very basic styles. 
// -----------------------------------------------------------------------------
#root {
  --icon-color: #abbbc2;
  --icon-selected-color: #3F9AB7;
  --sixty-color: #252836;
  --thirty-color: #1f1d2b;
  --ten-color: #3F9AB7;
  --btn-bg-color: #abbbc2;
  --btn-text-color: #252836;
  --btn2-bg-color: #3F9AB7;
  --btn2-text-color: #ffffff;
  --border-color: #393c49;
}
/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}
/**
 * Clear inner floats
 */
 .clearfix::after {
  clear: both;
  content: '';
  display: table;
}
.hidden {
  opacity: 0;
}
textarea:focus, input:focus{
  outline: none;
}
body {
  background-color: var(--sixty-color);
  color: white;
  user-select: none;
}
.nobreak {
  white-space: nowrap;
}
.svgText2 {
  fill: var(--ten-color);
}
.version {
  position: fixed;
  bottom: 18px;
  right: 36px;
  font-size: 12px;
  opacity: 0.3;
  z-index: 9999;
}