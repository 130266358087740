.order-summary-items-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
  .order-summary-item-quantity {
    // width: 58px;
    text-align: right;
  }
  .order-summary-item-price {
    // width: 52px;
    margin-right: 20px;
    text-align: right;
  }
  .order-summary-item-name {
    // width: calc(100% - 110px);
    flex-grow: 1;
    text-align: left;
  }
  .order-summary-items-header {
    display: flex;
    background: var(--thirty-color);
    width: 320px;
    flex: 0 0 18px;
    .order-summary-item-quantity,
    .order-summary-item-price,
    .order-summary-item-name {
      font-weight: bold;
      padding-left: 6px;
    }
    .order-summary-item-quantity {
      flex: 0 0 80px;
      text-align: center;
    }
    .order-summary-item-price {
      flex: 0 0 57px;
      text-align: right;
    }
    .order-summary-item-name {
      flex: 1 1 auto;
    }
  }
  .order-summary-items-body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 6px 8px 6px;
    overflow: auto;
    flex: 1 1 auto;
    // height: 100%;
    .order-summary-item-quantity,
    .order-summary-item-price,
    .order-summary-item-name,
    .order-summary-item {
      display: flex;
      margin-bottom: 6px;
      line-height: 1;
    }
  }
}
.bottom-summary-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  padding: 6px 12px;
  font-size: 14px;
  border-top: 1px solid var(--border-color);
}