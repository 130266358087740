.basket-item-container {
  width: 100%;
  background: var(--sixty-color);
  display: flex;
  flex-direction: column;
  padding: 6px 12px 6px 6px;
  border-radius: 5px;
  justify-content: space-between;
  position: relative;
  &.selected {
    background: var(--ten-color);
    .icon-btn {
      svg {
        .svgText {
          fill: white;
          stroke: white;
        }
      }
    }
  }
  .take-away-icon {
    position: absolute;
    bottom: 8px;
    left: 6px;
    height: 21px;
  }
  .top-row {
    display: flex;
    align-items: center;
    height: 26px;
    .icon-btn {
      height: 21px;
      width: 12px;
    }
    .name {
      flex: 1 1 auto;
      font-weight: 500;
      height: 26px;
      display: flex;
      align-items: center;
    }
    .count{
      display: flex;
      justify-content: flex-end;
      flex: 0 0 60px;
      height: 26px;
      .textField-root {
        min-width: unset;
        border-radius: 5px;
        .textField-base {
          color: white;
          padding: unset;
          height: 100%;
          background-color: unset;
          font-size: unset;
          .textField-input {
            text-align: center;
            padding: 2px;
            display: flex;
            height: 100%;
            font-size: 14px;
          }
        }
      }
    }
    .price {
      font-weight: 500;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 0 0 68px;
      height: 26px;
    }
  }
  .modfier-rows-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5em 0px;
    row-gap: 0.25px;
    font-size: 14px;
    padding-left: 34px;
    .modifier-row {
      display: flex;
      width: 100%;
      position: relative;
      height: 17px;
      &.not-available {
        opacity: 75%;
        filter: brightness(0.8);
      }
      .name {
        flex-grow: 1;
      }
      .count {
        // margin-right: 2.75em;
        // width: 3em;
        width: 50px;
        display: flex;
        justify-content: center;
        .number-container{
          display: flex;
          justify-content: center;
          width: 2.8em
        }
      }
      .price {
        // min-width: 100px;
        width: 68px;
        display: flex;
        justify-content: flex-end;
       
      }
    }
  }
  .bottom-row {
    display: flex;
    .textfield-container.textField-root {
      display: flex;
      flex-grow: 1;
      align-items: center;
      border-radius: 5px;
      .textField-label {
        font-size: 1em;
        font-family: "Barlow";
        list-style: unset;
        color: var(--icon-color);
        transform: translate(1em, 1.2em) scale(1);
        &.shrink{
          transform: translate(0.5em, 0.6em) scale(0.7);
        }
      }
      .textField-base {
        width: 100%;
        color: white;
        font-size: unset;
        .textField-input{
          padding: 1.75em 0.5em 0.6em;
        }
      }
    }
    .icon-btn {
      height: 16px;
      width: 16px;
    }
  }
}

.animation-disappear {
  opacity: 0;
  transition: opacity 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
