.tipp-screen-type1-root {
    width: 100%;
    height: 100%;
    display: flex;
    .left-side {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 0 0 350px;
        .order-summary-title {
            flex: 0 0 40px;
            text-align: center;
            font-size: 24px;
            padding: 6px;
            font-weight: bold;
        }
        .order-summary-items-container {
            flex: 1 1 auto;
            width: 350px;
            overflow: hidden;
        }
    }
    .right-side {
        flex: 1 1 auto;
        background: var(--sixty-color);
        position: relative;
        padding: 24px;
        .payments-needed {
            text-align: center;
            font-size: 36px;
            padding: 16px;
            font-weight: bold;
        }
        .message {
            text-align: center;
            font-size: 28px;
            font-weight: bold;
            padding-top: 16px;
            &.font-large {
                font-size: 38px;
            }
        }
        .tips-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: flex-start;
            padding: 24px 12px 12px;
            width: 100%;
            .tip-container {
                border: 1px solid var(--ten-color);
                border-radius: 10px;
                height: 120px;
                width: 130px;
                margin: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                &.selected {
                    background-color: var(--ten-color);
                    transition: background-color 0.3s ease-in-out;
                }
                &.not-selected {
                    background-color: transparent;
                    transition: background-color 0.3s ease-in-out;
                    &.loading {
                        opacity: 0.3;
                        transition: opacity 0.3s ease-in-out;
                    }
                    &.not-loading {
                        opacity: 1;
                        transition: opacity 0.3s ease-in-out;
                    }
                }
                .percent {
                    font-weight: bold;
                    font-size: 36px;
                    padding-top: 12px;
                }
                .amount {
                    padding-bottom: 16px;
                    font-size: 16px;
                }
            }
        }
        .payments-needed-container {
            text-align: center;
            margin: 48px 30px;
            padding: 12px;
            border-radius: 10px;
            background-color: var(--ten-color);
            
            .payments-needed-message {
                font-size: 28px;
                margin-bottom: 12px;
            }
            .payments-needed-amount {
                font-size: 36px;
                font-weight: bold;
            }
        }
        .image-container {
            position: absolute;
            bottom: 24px;
            right: 42px;
        }
    }
}